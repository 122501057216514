import React, {Component} from "react";
import { Glyphicon } from "react-bootstrap";
import {splitDuration} from "../util/Utils";

class WorkingShift extends Component {
    constructor(props) {
        super(props);
        let startDuration = props.shift.end ? props.shift.duration : Math.floor((Date.now() - new Date(props.shift.start + "Z").getTime()) / 1000);
        let duration = splitDuration(startDuration);
        let balance = splitDuration(props.shift.balance);
        let dailyBalance = splitDuration(props.shift.dailyBalance);
        let dailyDuration = splitDuration(props.shift.dailyDuration);
        let ongoing = !props.shift.end;

        // Leta igenom lista av konto id:n för att se om detta är dagens första förekomst av arbetspass för aktuellt konto.
        let firstEntryForAccount = false;

        if (ongoing) {
            firstEntryForAccount = true;
            let accountId = props.accountList[props.index];
            console.log("accountId: " + accountId);
            console.log("index: " + props.index);
            for (let i = 0; i < props.index; i++) {
                if (accountId === props.accountList[i]) {
                    console.log("Found current account id...");
                    firstEntryForAccount = false;
                    break;
                }
            }
        }

        console.log("Creating WorkingShift for shift: " + JSON.stringify(props.shift) +
            ", ongoing: " + ongoing +
            ", firstEntryForAccount: " + firstEntryForAccount);
        this.state = {
            duration: duration,
            balance: balance,
            dailyDuration: dailyDuration,
            dailyBalance: dailyBalance,
            shift: props.shift,
            ongoing: ongoing,
            firstEntryForAccount: firstEntryForAccount,
        };
    }

    componentDidMount() {
        if (this.state.ongoing) {
            this.timerId = setInterval(
                () => this.tick(),
                1000
            );
        }
    }

    componentWillUnmount() {
        if (this.state.ongoing) {
            clearInterval(this.timerId);
        }
    }

    tick() {
        let hours = this.state.duration.h;
        let minutes = this.state.duration.min;
        let seconds = this.state.duration.sec + 1;
        if (seconds === 60) {
            seconds = 0;
            minutes += 1;
        }
        if (minutes === 60) {
            minutes = 0;
            hours += 1;
        }
        let newDuration = {
            h: hours,
            min: minutes,
            sec: seconds
        };

        this.setState({ duration: newDuration });
    }

    static getFormattedTime(date) {
        //console.log("date: " + date);
        if (!date) {
            return null;
        }
        let localDate = new Date(date + "Z");
        //console.log("localDate: " + localDate);
        let hours = localDate.getHours();
        if (hours < 10) {
            hours = "0" + hours;
        }
        let minutes = localDate.getMinutes();
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        return hours + ":" + minutes;
    }

    render() {
        // noinspection JSUnresolvedVariable
        const account = this.state.shift.account.accountName;
        const start = WorkingShift.getFormattedTime(this.state.shift.start);
        const hours = this.state.duration.h;
        const minutes = this.state.duration.min;
        const seconds = this.state.duration.sec;
        let end, balance, dayHours, dayMinutes, dailyBalance;
        if (!this.state.ongoing) {
            end = WorkingShift.getFormattedTime(this.state.shift.end);
        }

        if (!this.state.ongoing || this.state.firstEntryForAccount) {
            balance = this.state.balance.sign + this.state.balance.h + 'h ' + this.state.balance.min + 'min';
            dayHours = this.state.dailyDuration.h;
            dayMinutes = this.state.dailyDuration.min;
            dailyBalance = this.state.dailyBalance.sign + this.state.dailyBalance.h + 'h ' + this.state.dailyBalance.min + 'min';
        }

        if (this.state.ongoing) {
            if (this.state.firstEntryForAccount) {
                return (
                    <div>
                        <b>{account}</b>
                        <br/>{start}, {hours}h {minutes}' {seconds}''
                        <br/>Dag: {dayHours}h {dayMinutes}min, diff: {dailyBalance}
                        <br/>Diff: {balance}
                        <br/><Glyphicon glyph="stop"/> Avsluta pass
                    </div>);
            } else {
                return (
                    <div>
                        <b>{account}</b>
                        <br/>{start}, {hours}h {minutes}' {seconds}''
                        <br/><Glyphicon glyph="stop"/> Avsluta pass
                    </div>);
            }
        }
        return (
            <div>
                <b>{account}</b>
                <br/>{start} - {end}, {hours}h {minutes}min
                <br/>Dag: {dayHours}h {dayMinutes}min, diff: {dailyBalance}
                <br/>Diff: {balance}
            </div>);
    }
}

export default WorkingShift;
