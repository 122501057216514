import React, { Component } from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import { API, Auth } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import "./NewShift.css";

export default class NewShift extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            currentUser: {},
            currentUserAccounts: [],
            selectedAccountIndex: 0,
            standardDailyDuration: 0,
            newShiftResult: ""
        };
    }

    async componentDidMount() {

        if (!this.props.isAuthenticated) {
            return;
        }

        try {
            // Hämta inloggad användare
            const cognitoUser = await Auth.currentAuthenticatedUser();

            // Hämta användardata för inloggad användare.
            if (cognitoUser) {
                const currentUser = await NewShift.getUserData(cognitoUser.username);
                console.log(JSON.stringify(currentUser));

                let accounts = currentUser.users[0].accounts.sort(
                    (a1, a2) => a1.accountName.localeCompare(a2.accountName));
                console.log(JSON.stringify(accounts));

                // noinspection JSUnresolvedVariable
                this.setState({
                    currentUser: currentUser.users[0],
                    currentUserAccounts: accounts,
                    standardDailyDuration: currentUser.users[0].accounts[0].standardDailyDuration / 3600,
                    selectedAccountIndex: "0",
                });
            }
        } catch (e) {
            alert(e);
        }

        this.setState({ isLoading: false });
    }

    static getUserData(userId) {
        const path = "/users/" + userId;
        console.log("Calling API with path: " + path);
        return API.get("time-clock", path, null);
    }

    validateForm() {
        return this.state.selectedAccountIndex && this.state.standardDailyDuration !== "";
    }

    handleChange = event => {
        console.log("id: " + event.target.id);
        console.log("value: " + event.target.value);
        this.setState({
            [event.target.id]: event.target.value
        });
        if (event.target.id === "selectedAccountIndex") {
            this.setState({
                "standardDailyDuration": this.state.currentUserAccounts[event.target.value].standardDailyDuration / 3600
            });
        }
    };

    handleSubmit = async event => {
        event.preventDefault();

        this.setState({ isLoading: true });

        console.log(this.state.selectedAccountIndex);

        try {
            // noinspection JSUnresolvedVariable
            let createShiftData = {
                shift: {
                    userId: this.state.currentUser.userId,
                    account: this.state.currentUser.accounts[this.state.selectedAccountIndex],
                }
            };
            createShiftData.shift.account.standardDailyDuration = this.state.standardDailyDuration * 3600;

            let result = await NewShift.createShift(createShiftData);
            console.log(result.message);

            this.setState( {
                isLoading: false,
                newShiftResult: result.message,
            });
            this.props.history.push("/");
        } catch (e) {
            console.log(e.stack);
            alert(e);
            this.setState({ isLoading: false });
        }
    };

    static createShift(createShiftData) {

        console.log(JSON.stringify(createShiftData));
        return API.post("time-clock", "/shifts", {
            body: createShiftData
        });
    }

    render() {
        // noinspection JSUnresolvedVariable
        return (
            <div className="NewShift">
                <form onSubmit={this.handleSubmit}>
                    <FormGroup controlId="selectedAccountIndex">
                        <ControlLabel>Kontonamn</ControlLabel>
                        <FormControl componentClass="select" onChange={this.handleChange} value={this.state.selectedAccountIndex}>
                            {
                                this.state.currentUserAccounts.map((account, index) =>
                                    <option key={index} value={index}>{account.accountName}</option>
                                )
                            }
                        </FormControl>
                    </FormGroup>
                    <FormGroup controlId="standardDailyDuration">
                        <ControlLabel>Standardtid (h)</ControlLabel>
                        <FormControl type="text" value={this.state.standardDailyDuration} onChange={this.handleChange}/>
                    </FormGroup>
                    <LoaderButton
                        block
                        bsStyle="primary"
                        bsSize="large"
                        disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Stämpla in"
                        loadingText="Laddar…"
                    />
                </form>
            </div>
        );
    }
}
