import React, { Component } from "react";
import { Glyphicon, ListGroup, ListGroupItem, PageHeader } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import WorkingShift from "../components/WorkingShift";
import {API, Auth} from "aws-amplify";
import DayPickerInput from "react-day-picker/DayPickerInput";

import "./DailyShifts.css";
import "react-day-picker/lib/style.css";

export default class DailyShifts extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            day: "",
            shifts: []
        };
    }

    async componentDidMount() {

        if (!this.props.isAuthenticated) {
            return;
        }

        try {

            this.setState( {day: `${this.props.match.params.day}`});
            // Hämta inloggad användare
            const cognitoUser = await Auth.currentAuthenticatedUser();

            // Hämta användares skift för idag:
            const dailyShifts = await this.dailyShifts(cognitoUser.username);
            console.log("Todays shifts for user: " + JSON.stringify(dailyShifts.shifts));
            this.setState({ shifts: dailyShifts.shifts });
        } catch (e) {
            console.log(e);
        }

        this.setState({ isLoading: false });
    }

    dailyShifts(userId) {

        let init = {
            'queryStringParameters': {
                'userId': userId,
                'day': this.state.day
            }
        };
        console.log("Calling API with path: /shifts, params: " + JSON.stringify(init));
        return API.get("time-clock", "/shifts", init);
    }

    handleDayChange = async (day) => {

        this.setState({day: day.toLocaleDateString()})

        console.log('handle day change, new day: ' + this.state.day);
        let dailyShifts = {shifts: []};
        try {
            const cognitoUser = await Auth.currentAuthenticatedUser();
            dailyShifts = await this.dailyShifts(cognitoUser.username, this.state.day);
            console.log("Todays shifts for user: " + JSON.stringify(dailyShifts.shifts));
        } catch (e) {
            console.log(e);
        }
        this.setState({ shifts: dailyShifts.shifts });
    };

    static renderShiftList(shifts) {

        return shifts.map(
            (shift) => {
                // noinspection JSUnresolvedVariable
                return <LinkContainer key={shift.shiftId} to={`/shifts/${shift.shiftId}`}>
                    <ListGroupItem header={shift.account.name}>
                        <WorkingShift shift={shift} />
                    </ListGroupItem>
                </LinkContainer>;
            }
        );
    }

    renderShifts() {
        return (
            <div className="shifts">
                <PageHeader>Arbetspass <br/><small>{this.state.day}</small></PageHeader>
                {this.state.isLoading && <Glyphicon glyph="refresh" className="spinning" />}
                <ListGroup>
                    {!this.state.isLoading && DailyShifts.renderShiftList(this.state.shifts)}
                </ListGroup>
                <p>Välj dag</p>
                <DayPickerInput
                    onDayChange={this.handleDayChange}
                    dayPickerProps={{
                        firstDayOfWeek: 1,
                        showWeekNumbers: true,
                        locale: "sv"
                    }}
                />
            </div>
        );
    }

    render() {
        return (
            <div className="DailyShifts">
                {this.props.isAuthenticated && this.renderShifts()}
            </div>
        );
    }
}
