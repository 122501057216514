import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import NewShift from "./containers/NewShift";
import Shifts from "./containers/Shifts";
import DailyShifts from "./containers/DailyShifts";
import NotFound from "./containers/NotFound";
import AppliedRoute from "./components/AppliedRoute";

export default ({ childProps }) =>
    <Switch>
        <AppliedRoute path="/" exact component={Home} props={childProps} />
        <AppliedRoute path="/login" exact component={Login} props={childProps} />
        <AppliedRoute path="/signup" exact component={Signup} props={childProps} />
        <AppliedRoute path="/shifts/new" exact component={NewShift} props={childProps} />
        <AppliedRoute path="/shifts/:id" exact component={Shifts} props={childProps} />
        <AppliedRoute path="/dailyShifts/:day" exact component={DailyShifts} props={childProps} />
        { /* Finally, catch all unmatched routes */ }
        <Route component={NotFound} />
    </Switch>;
