import React, { Component } from "react";
import { Glyphicon, ListGroup, ListGroupItem, PageHeader } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import DayPickerInput from "react-day-picker/DayPickerInput";
import WorkingShift from "../components/WorkingShift";
// noinspection NpmUsedModulesInstalled
import _ from 'lodash';
import {API, Auth} from "aws-amplify";

import "react-day-picker/lib/style.css";
import "./Home.css";

export default class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            shifts: []
        };
    }

    async componentDidMount() {
        if (!this.props.isAuthenticated) {
            return;
        }

        try {
            // Hämta inloggad användare
            const cognitoUser = await Auth.currentAuthenticatedUser();

            // Hämta användares skift för idag:
            const todaysShifts = await Home.todaysShifts(cognitoUser.username);
            console.log("Todays shifts for user: " + JSON.stringify(todaysShifts.shifts));
            this.setState({ shifts: todaysShifts.shifts });
        } catch (e) {
            console.log(e);
        }

        this.setState({ isLoading: false });
    }

    static todaysShifts(userId) {

        let init = {
            'queryStringParameters': {
                'userId': userId,
                'day': new Date().toLocaleDateString()
            }
        };
        console.log("Calling API with path: /shifts, params: " + JSON.stringify(init));
        return API.get("time-clock", "/shifts", init);
    }

    handleDayChange = (day) => {
        console.log(day.toLocaleDateString());
        let path = "/DailyShifts/" + day.toLocaleDateString();
        console.log("Path: " + path);
        this.props.history.push(path);
    };


    renderShiftList(shifts) {
        let shiftIterator;
        if (shifts.length < 1) {
            shiftIterator = [{}];
        } else {
            shiftIterator = shifts[shifts.length - 1].end ? shifts.concat([{}]) : shifts;
        }

        // Lista för att ta reda på om aktuellt konto är första idag.
        const accountList = shifts.map( shift => shift.account.accountId );

        return shiftIterator.map(
            (shift, i) => {
                // noinspection JSUnresolvedVariable,JSUnresolvedFunction
                return !_.isEmpty(shift)
                    ? <LinkContainer key={shift.shiftId} to={`/shifts/${shift.shiftId}`}>
                        <ListGroupItem header={shift.account.name}>
                            <WorkingShift shift={shift} index={i} accountList={accountList}/>
                        </ListGroupItem>
                    </LinkContainer>
                    : <LinkContainer key="new" to="/shifts/new">
                        <ListGroupItem>
                            <div>
                                <Glyphicon glyph="play-circle" /> Starta pass
                            </div>
                        </ListGroupItem>
                    </LinkContainer>;
            }
        );
    }

    static renderLander() {
        return (
            <div className="lander">
                <h1>Stämpelklockan</h1>
                <p>Håll koll på din arbetade tid.</p>
            </div>
        );
    }

    renderShifts() {
        return (
            <div className="shifts">
                <PageHeader>Dagens arbetspass<br/><small>{new Date().toLocaleDateString()}</small></PageHeader>
                {this.state.isLoading && <Glyphicon glyph="refresh" className="spinning" />}
                <ListGroup>
                    {!this.state.isLoading && this.renderShiftList(this.state.shifts)}
                </ListGroup>

                <p>Välj dag</p>
                <DayPickerInput
                    onDayChange={this.handleDayChange}
                    dayPickerProps={{
                        firstDayOfWeek: 1,
                        showWeekNumbers: true,
                        locale: "sv"
                    }}
                />
            </div>
        );
    }

    render() {
        return (
            <div className="Home">
                {this.props.isAuthenticated ? this.renderShifts() : Home.renderLander()}
                <div className="footer">version: 1.0.4</div>
            </div>
        );
    }
}
