export default {
  s3: {
    REGION: "eu-west-2",
    BUCKET: "YOUR_S3_UPLOADS_BUCKET_NAME"
  },
  apiGateway: {
    REGION: "eu-west-2",
    URL: "https://pxje0p6wqh.execute-api.eu-west-2.amazonaws.com/prod"
  },
  cognito: {
    REGION: "eu-west-2",
    USER_POOL_ID: "eu-west-2_vfMVHwVgX",
    APP_CLIENT_ID: "670ibbrmdg81pn9rdarobnabqi",
    IDENTITY_POOL_ID: "eu-west-2:a9210f08-81fd-489e-ab99-cb32e4c3dc0e"
  }
};
