import React, { Component } from "react";
import {API, Auth} from "aws-amplify";
import {ControlLabel, FormGroup, FormControl, Glyphicon} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Shifts.css";

export default class Shifts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isDeleting: null,
            shift: null,
            shiftStart: "",
            shiftEnd: "",
            standardDailyDuration: 0
        };
    }

    async componentDidMount() {
        try {
            // Hämta inloggad användare
            const cognitoUser = await Auth.currentAuthenticatedUser();

            // Hämta skift
            const shiftData = await this.getShift(cognitoUser.username);

            let shift = {};
            if (shiftData.shifts) {
                shift = shiftData.shifts[0];
            }

            console.log("Found shift: " + JSON.stringify(shift));

            if (!shift.end) {
                // If no end time on current shift. End shift directly and go back to home page.
                await Shifts.saveShift(shift, cognitoUser.username);

                console.log("Ending shift and going back to home page.");
                this.props.history.push("/");
            } else {
                this.setState({
                    shift,
                    shiftStart: shift.start,
                    shiftEnd: shift.end,
                    standardDailyDuration: shift.account.standardDailyDuration / 3600
                });
                console.log("start: " + this.state.shiftStart);
            }
            this.setState({ isLoading: false });
        } catch (e) {
            this.setState({ isLoading: false });
            alert(e);
        }
    }

    getShift(userId) {

        let path = `/shifts/${this.props.match.params.id}`;
        let init = {
            'queryStringParameters': {
                'userId': userId
            }
        };

        console.log("Calling API with path: " + path + ", params: " + JSON.stringify(init));
        return API.get("time-clock", path, init);
    }

    validateForm() {
        return this.state.shiftStart.length > 0;
    }

    static async saveShift(shift, userId) {
        console.log("Shift to save: " + JSON.stringify(shift));
        // noinspection JSUnresolvedVariable
        let path = "/shifts/" + shift.shiftId;
        let init = {
            'queryStringParameters': {
                'userId': userId
            },
            'body': {
                'shift': shift
            }
        };

        console.log("Calling API with path: " + path + ", params: " + JSON.stringify(init));

        await API.put("time-clock", path, init);
    }

    handleChange = event => {
        console.log(event.target.value);
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleSubmit = async event => {
        event.preventDefault();

        this.setState({ isLoading: true });

        try {
            let updatedShift = this.state.shift;
            updatedShift.start = this.state.shiftStart;
            updatedShift.end = this.state.shiftEnd;
            updatedShift.account.standardDailyDuration = this.state.standardDailyDuration * 3600;

            // Hämta inloggad användare
            const cognitoUser = await Auth.currentAuthenticatedUser();

            await Shifts.saveShift(updatedShift, cognitoUser.username);
            this.setState({ isLoading: false });
            //this.props.history.push("/");
        } catch (e) {
            alert(e);
            this.setState({ isLoading: false });
        }

    };

    // handleDelete = async event => {
    //     event.preventDefault();
    //
    //     const confirmed = window.confirm(
    //         "Are you sure you want to delete this shift?"
    //     );
    //
    //     if (!confirmed) {
    //         return;
    //     }
    //
    //     this.setState({ isDeleting: true });
    // };

    render() {
        return (
            <div className="Shifts">
                {!this.state.shift && this.state.isLoading && <Glyphicon glyph="refresh" className="spinning" />}
                {this.state.shift &&
                <form onSubmit={this.handleSubmit}>
                    <FormGroup controlId="shiftStart">
                        <ControlLabel>Start</ControlLabel>
                        <FormControl onChange={this.handleChange} type="text" value={this.state.shiftStart}/>
                    </FormGroup>
                    <FormGroup controlId="shiftEnd">
                        <ControlLabel>Slut</ControlLabel>
                        <FormControl onChange={this.handleChange} type="text" value={this.state.shiftEnd}/>
                    </FormGroup>
                    <FormGroup controlId="standardDailyDuration">
                        <ControlLabel>Standardtid (h)</ControlLabel>
                        <FormControl onChange={this.handleChange} type="text" value={this.state.standardDailyDuration}/>
                    </FormGroup>
                    <LoaderButton
                        block
                        bsStyle="primary"
                        bsSize="large"
                        disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Spara"
                        loadingText="Sparar…"
                    />
                    {/*<LoaderButton*/}
                    {/*    block*/}
                    {/*    bsStyle="danger"*/}
                    {/*    bsSize="large"*/}
                    {/*    isLoading={this.state.isDeleting}*/}
                    {/*    onClick={this.handleDelete}*/}
                    {/*    text="Radera"*/}
                    {/*    loadingText="Raderar…"*/}
                    {/*/>*/}
                </form>}
            </div>
        );
    }
}
