
/**
* Split up duration in seconds to an object with hours, minutes and seconds
*/
export const splitDuration = (durationInSeconds) => {
  let sign = Math.sign(durationInSeconds) < 0 ? '-' : '+';
  let absDurationInSeconds = Math.abs(durationInSeconds);
  let hours = Math.floor(absDurationInSeconds / 3600);
  let minutes = Math.floor(absDurationInSeconds % 3600 / 60);
  let seconds = absDurationInSeconds % 3600 % 60;

  return {
    h: hours,
    min: minutes,
    sec: seconds,
    sign: sign,
  };
}
